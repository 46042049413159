const SwipesheetCross = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3334 2.66669L2.66675 13.3334"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66675 2.66669L13.3334 13.3334"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SwipesheetCross;
