const AllPhotos = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      d="M14.5443 2.51636L4.45885 2.51636C3.66313 2.51636 3.01807 3.16142 3.01807 3.95714L3.01807 14.0426C3.01807 14.8384 3.66313 15.4834 4.45885 15.4834L14.5443 15.4834C15.3401 15.4834 15.9851 14.8384 15.9851 14.0426L15.9851 3.95714C15.9851 3.16142 15.3401 2.51636 14.5443 2.51636Z"
      stroke="white"
      strokeWidth="1.44079"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.97951 7.55913C7.57631 7.55913 8.0601 7.07533 8.0601 6.47854C8.0601 5.88175 7.57631 5.39795 6.97951 5.39795C6.38272 5.39795 5.89893 5.88175 5.89893 6.47854C5.89893 7.07533 6.38272 7.55913 6.97951 7.55913Z"
      stroke="white"
      strokeWidth="1.44079"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9838 11.161L12.3818 7.55908L4.45752 15.4834"
      stroke="white"
      strokeWidth="1.44079"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AllPhotos;
